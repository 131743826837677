require('./bootstrap')

$(function () {
    let noticeTimer = null;

    // 通知公告滚动
    function noticeScroll() {
        $('.scroll-box')
            .find('div:first')
            .animate({marginTop: '-36px'}, 3000, function () {
                // $(this).css({ marginTop: '0px' }).remove('div.col:first').remove('div.col:first')
                //   console.log($(this).find('div.col:first'))
                $(this).css({marginTop: '0px'})
                $(this).find('div:first').appendTo(this)
                $(this).find('div:first').appendTo(this)
            })
    }

    $('.scroll-box').hover(
        function () {
            clearInterval(noticeTimer)
        },
        function () {
            noticeTimer = setInterval(noticeScroll, 2000)
        }
    )
    noticeTimer = setInterval(noticeScroll, 2000)

    // 回到顶部按钮
    $.scrollUp({scrollText: ''})

    // 首页轮播图
    $('#index_banner').carousel()

    // 新闻版块
    $('#index_news-box-carousel').carousel()
    $('#index_news-box-tab a').on('click', function (e) {
        $(this).tab('show')
        e.preventDefault()
    })

    // 科技成果
    $('#index_kjcg-carousel').carousel()
    $('#index_kjcg-carousel').on('slide.bs.carousel', function (e) {
        $('.index_kjcg .card .left .page').attr('data-index', e.to)
        $('.index_kjcg .card .left .item').eq(e.to).addClass('show').siblings().removeClass('show')
    })

    // 党建观澜
    $('#gov_djgl-carousel').carousel()

    // 主营业务
    $('.index_zyyw .left')
        .children('.item')
        .hover(
            function (e) {
                $(this).addClass('active')
                $('.index_zyyw .right').children('a').attr('href', $(this).attr('href'))
                $('.index_zyyw .right').find('.img').attr('src', $(this).children('.cover').attr('src'))
            },
            function (e) {
                $(this).removeClass('active')
            }
        )
    // 上一页
    $('.index_zyyw .page')
        .children('.fa-chevron-left')
        .on('click', function (e) {
            $('.index_zyyw .page').find('label').children('span').text('1')
            $('.index_zyyw .row .left').eq(0).addClass('show').siblings().removeClass('show')
        })
    // 下一页
    $('.index_zyyw .page')
        .children('.fa-chevron-right')
        .on('click', function (e) {
            $('.index_zyyw .page').find('label').children('span').text('2')
            $('.index_zyyw .row .left').eq(1).addClass('show').siblings().removeClass('show')
        })

    // 科技成果
    // 上一页
    $('.index_kjcg .card .left .page')
        .children('.fa-chevron-left')
        .on('click', function (e) {
            var index = $('.index_kjcg .card .left .page').attr('data-index')
            if (index == 0) index = 4
            $('.index_kjcg .card .left .page').attr('data-index', Number(index) - 1)
            $('.index_kjcg .card .left .item')
                .eq(Number(index) - 1)
                .addClass('show')
                .siblings()
                .removeClass('show')
            $('#index_kjcg-carousel').carousel(Number(index) - 1)
        })
    // 下一页
    $('.index_kjcg .card .left .page')
        .children('.fa-chevron-right')
        .on('click', function (e) {
            var index = $('.index_kjcg .card .left .page').attr('data-index')
            if (index == 3) index = -1
            $('.index_kjcg .card .left .page').attr('data-index', Number(index) + 1)
            $('.index_kjcg .card .left .item')
                .eq(Number(index) + 1)
                .addClass('show')
                .siblings()
                .removeClass('show')
            $('#index_kjcg-carousel').carousel(Number(index) + 1)
        })

    // 党建观澜
    // 上一页
    $('.index_djgl .page')
        .children('.fa-chevron-left')
        .on('click', function (e) {
            $('.index_djgl .page').find('label').children('span').text('1')
            $('.index_djgl .row').eq(0).addClass('show').siblings().removeClass('show')
        })
    // 下一页
    $('.index_djgl .page')
        .children('.fa-chevron-right')
        .on('click', function (e) {
            $('.index_djgl .page').find('label').children('span').text('2')
            $('.index_djgl .row').eq(1).addClass('show').siblings().removeClass('show')
        })

    // 顶部搜索
    var showSearch = false,
        $nav = $('.header-box .nav'),
        inputBox = $('.search-box .form-group'),
        input = inputBox.children('input')

    function doSearch(keyword) {
        if (!keyword) {
            return layer.msg('请输入关键字', {icon: 5})
        }
        window.location.href = '/search?key=' + keyword
    }

    $(document).on('click', function (e) {
        if (showSearch) {
            inputBox.hide('fast', function () {
                showSearch = false
                $nav.show()
            })
        }
        e.stopPropagation()
    })
    $('.search-box .search-icon').on('click', function (e) {
        if (!showSearch) {
            inputBox.show('fast', function () {
                showSearch = true
                $nav.hide()
            })
        } else {
            doSearch(input.val())
        }
        e.stopPropagation()
        return false
    })
    $('.search-box .fa-times').on('click', function (e) {
        inputBox.hide('fast', function () {
            showSearch = false
            $nav.show()
        })
        e.stopPropagation()
        return false
    })
    input.on('click', function (e) {
        e.stopPropagation()
        return false
    })

    // 搜索页面
    $('.is-search .form-group')
        .children('button')
        .on('click', function (e) {
            doSearch($('.is-search .form-group').children('input').val())
            e.stopPropagation()
            return false
        })
})
